// src/components/Question.js
import React from 'react';

const Question = ({ question, options, selectedOption, onSelect }) => {
  return (
    <div className="container">
      <h2 className="question-title" dangerouslySetInnerHTML={{ __html: question }} />
      <div className="options-grid">
        {options.map((option, index) => (
          <div 
            key={index} 
            className="option" 
            onClick={() => onSelect(option)} 
            style={{ backgroundColor: selectedOption && selectedOption.text === option.text ? '#e0f7fa' : 'white' }}
          >
            {option.image && <img src={option.image} alt={option.text} className="option-image" />}
            <label>{option.text}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
