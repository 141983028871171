import React from 'react';
import { destinations } from '../data';

const Result = ({ answers, onRestart }) => {
  if (!answers || answers.length === 0) {
    return <div>Nenhuma resposta fornecida.</div>;
  }

  const scoreCount = {};

  answers.forEach(answer => {
    if (answer && answer.score !== undefined) {
      if (scoreCount[answer.score]) {
        scoreCount[answer.score]++;
      } else {
        scoreCount[answer.score] = 1;
      }
    }
  });

  const maxScore = Object.keys(scoreCount).reduce((a, b) => scoreCount[a] > scoreCount[b] ? a : b, 0);
  const resultDestination = destinations[maxScore] || { description: "Destino desconhecido", image: "" };

  return (
    <div className="result-container">
      <div className="result-content">
        <h2>Seu destino ideal é:</h2>
        <h3>{resultDestination.description}</h3>
        {resultDestination.image && <img src={resultDestination.image} alt={resultDestination.description} className="result-image" />}
        <button onClick={onRestart} className="restart-button">Recomeçar Quiz</button>
      </div>
    </div>
  );
};

export default Result;
