// src/components/Nav.js
import React from 'react';

const Nav = () => {
  return (
    <nav className="nav">
      <h1>Sua próxima viagem vai ser para: </h1>
    </nav>
  );
};

export default Nav;