import React, { useState } from 'react';
import Intro from './components/Intro'; // Importando o componente Intro
import Quiz from './components/Quiz';
import Result from './components/Result';
import Nav from './components/Nav';
import Footer from './components/Footer';

const App = () => {
  const [answers, setAnswers] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [isStarted, setIsStarted] = useState(false); // Novo estado para controlar a introdução

  const handleFinish = (answers) => {
    setAnswers(answers);
    setIsFinished(true);
    localStorage.removeItem('answers'); // Limpa o localStorage ao finalizar
  };

  const handleRestart = () => {
    setAnswers([]);
    setIsFinished(false);
    setIsStarted(false); // Retorna à introdução
    localStorage.removeItem('answers'); // Limpa o localStorage ao recomeçar
  };

  const handleStart = () => {
    setIsStarted(true); // Inicia o quiz
  };

  return (
    <div className="app">
      <Nav />
      <div className="container">
        {!isStarted && !isFinished && <Intro onStart={handleStart} />}
        {isStarted && !isFinished && <Quiz onFinish={handleFinish} />}
        {isFinished && <Result answers={answers} onRestart={handleRestart} />}
      </div>
      <Footer />
    </div>
  );
};

export default App;
