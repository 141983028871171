// src/data.js
export const questions = [
  {
    question: "Qual é seu ator favorito?",
    options: [
      { text: "Leonardo DiCaprio", score: 1, image: "/images/leonardo_dicaprio.webp" },
      { text: "Will Smith", score: 6, image: "/images/will_smith.webp" },
      { text: "Daniel Radcliffe", score: 5, image: "/images/daniel_radcliffe.avif" },
      { text: "Wagner Moura", score: 4, image: "/images/wagner_moura.webp" },
      { text: "Tom Hanks", score: 1, image: "/images/Tom_Hanks.webp" },
      { text: "Robert De Niro", score: 1, image: "/images/Robert_De_Niro.jpeg" },
      { text: "Ryan Gosling", score: 6, image: "/images/Ryan_Gosling.webp" },
      { text: "Al Pacino", score: 1, image: "/images/Al_Pacino.webp" }
    ]
  },
  {
    question: "Qual é sua atriz favorita?",
    options: [
      { text: "Emma Stone", score: 6, image: "/images/emma_stone.jpeg" },
      { text: "Rachel McAdams", score: 3, image: "/images/Rachel_McAdams.jpeg" },
      { text: "Julia Roberts", score: 6, image: "/images/julia_roberts.jpeg" },
      { text: "Meryl Streep", score: 1, image: "/images/meryl_streep.jpeg"},
      { text: "Zoe Saldaña", score: 6, image: "/images/zoe_saldana.webp" },
      { text: "Emma Watson", score: 5, image: "/images/emma_watson.webp" }
    ]
  },
  {
    question: "Qual é seu tipo de música favorita?",
    options: [
      { text: "Rock/Metal", score: 1, image: "/images/rock_metal.jpeg" },
      { text: "Jazz", score: 2, image: "/images/jazz.jpeg" },
      { text: "Bossa Nova/MPB", score: 4, image: "/images/bossa_nova.jpeg" },
      { text: "Clássica", score: 3, image: "/images/musica_classica.avif" },
      { text: "Hip-Hop/Rap", score: 6, image: "/images/hiphop.jpeg" },
      { text: "Pop", score: 6, image: "/images/pop.jpeg" },
      { text: "Samba", score: 4, image: "/images/samba.jpeg" }
    ]
  },
  {
    question: "Qual é sua série favorita?",
    options: [
      { text: "Friends", score: 1, image: "/images/friends_wallpaper.jpeg" },
      { text: "Peaky Blinders", score: 5, image: "/images/peaky_blinders.jpeg" },
      { text: "Game Of Thrones", score: 2, image: "/images/game_of_thrones.jpeg" },
      { text: "The Office", score: 1, image: "/images/the_office.jpeg" },
      { text: "Coisa Mais Linda", score: 4, image: "/images/coisa_mais_linda.jpeg" },
      { text: "How I Met Your Mother", score: 1, image: "/images/how_i_met_your_mother.jpeg" }
    ]
  },
  {
    question: "Qual é seu livro favorito?",
    options: [
      { text: "Harry Potter", score: 5, image: "/images/harry_potter.jpeg" },
      { text: "Gone Girl", score: 1, image: "/images/gone_girl_livro.jpeg" },
      { text: "The Great Gatsby", score: 1, image: "/images/the_great_book.jpg" },
      { text: "Dune", score: 2, image: "/images/dune_livro.jpeg" },
      { text: "100 anos de solidão", score: 4, image: "/images/100_anos_livro.jpg" },
      { text: "O Corvo", score: 3, image: "/images/o_corvo_livro.jpg" }
    ]
  },
  {
    question: "Depois de um dia de trabalho, que tipo de filme você gosta de assistir?",
    options: [
      { text: "Ação", score: 6, image: "/images/acao.jpeg" },
      { text: "Comédia", score: 1, image: "/images/comedia.webp" },
      { text: "Terror", score: 2, image: "/images/terror.jpeg" },
      { text: "Romance", score: 3, image: "/images/romance.jpeg" },
      { text: "Drama", score: 1, image: "/images/drama.avif" },
      { text: "Fantasia", score: 5, image: "/images/fantasia_wallpaper.jpeg" }
    ]
  },
  {
    question: "Finalmente, qual é seu filme favorito?",
    options: [
      { text: "Tropa de Elite", score: 4, image: "/images/tropa_de_elite.jpeg" },
      { text: "A Origem", score: 2, image: "/images/a_origem.jpeg" },
      { text: "La La Land", score: 6, image: "/images/la_la_land.jpeg" },
      { text: "Velozes e Furiosos", score: 6, image: "/images/velozes_e_furiosos.jpeg" },
      { text: "Harry Potter", score: 5, image: "/images/harry_potter_filme.jpeg" },
      { text: "O Exorcista", score: 2, image: "/images/o_exorcista.jpeg" }
    ]
  }
];

export const destinations = {
  1: { description: "Nova York, EUA: Cosmopolita e vibrante", image: "/images/new_york.jpeg" },
  2: { description: "Tóquio, Japão: Futurista e tradicional", image: "/images/tokyo.jpg" },
  3: { description: "Paris, França: Romântica e cultural", image: "/images/Paris_wallpaper.jpeg" },
  4: { description: "Rio de Janeiro, Brasil: Festiva e tropical", image: "/images/rio_de_janeiro.jpeg" },
  5: { description: "Londres, Reino Unido: Histórica e moderna", image: "/images/londres_wallpaper.jpeg" },
  6: { description: "Los Angeles, EUA: Glamourosa e ensolarada", image: "/images/los_angeles.jpeg" }
};