import React from 'react';

const Intro = ({ onStart }) => {
  return (
    <div className="intro-container">
      <div className="intro-content">
        <h2>Teste: Seu filme favorito vai te levar para:</h2>
        <p>O filme favorito de uma pessoa pode dizer muito sobre sua personalidade, até qual seria o destino ideal para as tão sonhadas férias.</p>
        <p>Se você está planejando viajar e ainda não sabe para onde quer ir, responda nosso quiz para ver o destino que mais se aproxima da sua personalidade.</p>
        <button onClick={onStart} className="start-button">Iniciar Quiz</button>
      </div>
    </div>
  );
};

export default Intro;
