import React, { useState, useEffect } from 'react';
import Question from './Question';
import ProgressBar from './ProgressBar';
import { questions } from '../data';

const Quiz = ({ onFinish }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const savedAnswers = JSON.parse(localStorage.getItem('answers')) || [];
    setAnswers(savedAnswers);
  }, []);

  useEffect(() => {
    setSelectedOption(answers[currentQuestion] || null);
  }, [currentQuestion, answers]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = option;
    setAnswers(updatedAnswers);
    localStorage.setItem('answers', JSON.stringify(updatedAnswers));
  };

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
    } else {
      onFinish(answers);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedOption(answers[currentQuestion - 1] || null);
    }
  };

  return (
    <div className="quiz-container">
      <ProgressBar currentStep={currentQuestion + 1} totalSteps={questions.length} />
      <Question 
        question={questions[currentQuestion].question}
        options={questions[currentQuestion].options}
        selectedOption={selectedOption}
        onSelect={handleSelect}
      />
      <div className="button-container">
        {currentQuestion > 0 && (
          <>
            <button onClick={handlePreviousQuestion}>Voltar</button>
            <div className="spacer"></div>
          </>
        )}
        <button onClick={handleNextQuestion}>
          {currentQuestion < questions.length - 1 ? 'Próximo' : 'Finalizar'}
        </button>
      </div>
    </div>
  );
};

export default Quiz;
